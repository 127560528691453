import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import Moment from "moment";

// Constants
import { loadStates } from "src/constants/load_states";

// Containers
import { NonWoundAssessmentDetailsHeaderContainer } from "src/containers/non_wound_assessment_details_header_container";
import {
  NonWoundAssessmentSummaryContainer,
  NonWoundAssessmentSummaryWithPatientLastFacilityContainer
} from "src/containers/non_wound_assessment_summary_container";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { LoadingComponent } from "src/components/loading_component";
import { SchemaAnswersComponent } from "src/components/schema_answers_component";
import { Button, Breadcrumbs } from "src/ui-kit";

// Styles
import styles from "./style.module.less";

//Translations
import i18n from "src/translations";

export class NonWoundAssessmentDetailsComponent extends React.PureComponent {
  static propTypes = {
    study: PropTypes.object,
    assessmentAnswerGroups: PropTypes.array.isRequired,
    assessmentAnswers: PropTypes.array.isRequired,
    assessmentSchema: PropTypes.object,
    assessmentAnswersContextLoadState: PropTypes.object.isRequired,
    assessmentsContextLoadState: PropTypes.object.isRequired,
    breadcrumbsEnabled: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired,
    lastFacilityQuery: PropTypes.string,
    patientId: PropTypes.string
  };

  /**
   * Render the top grey bar in the component
   * TODO: the navbar should be a single component that is router dependent
   */
  renderControlNavbar() {
    const { patient } = this.props;
    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <GridRow className="panel-headline">
        <GridColumn xs="1">
          <Button
            onClick={this.props.goBack}
            className={styles["margintop8px"]}
          >
            Back
          </Button>
        </GridColumn>
        <GridColumn xs="11">
          <h1 className={styles["margintop11px"]}>
            {firstName} {lastName}
          </h1>
        </GridColumn>
      </GridRow>
    );
  }

  renderBreadcrumbs() {
    const { patient } = this.props;
    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <Breadcrumbs
        className={styles["breadcrumb-headline"]}
        items={[
          {
            text: `${firstName} ${lastName}`,
            onClick: this.props.goBack
          },
          {
            text: `Wound ${i18n.t("text.evaluation")}`,
            active: true
          }
        ]}
      />
    );
  }

  renderNonWoundAssessmentSummaryContainer = (aa, assessmentSchema) => {
    const { lastFacilityQuery, patientId } = this.props;
    if (lastFacilityQuery) {
      return (
        <NonWoundAssessmentSummaryWithPatientLastFacilityContainer
          assessmentAnswer={aa}
          assessmentAnswerGroupId={get(
            aa,
            "attributes.assessmentAnswerGroupId",
            ""
          )}
          assessmentSchema={assessmentSchema}
          patientId={patientId}
        />
      );
    } else {
      return (
        <NonWoundAssessmentSummaryContainer
          assessmentAnswer={aa}
          assessmentAnswerGroupId={get(
            aa,
            "attributes.assessmentAnswerGroupId",
            ""
          )}
          assessmentSchema={assessmentSchema}
        />
      );
    }
  };

  render() {
    const {
      study,
      assessmentAnswerGroups,
      assessmentAnswers,
      assessmentSchema,
      assessmentAnswersContextLoadState,
      assessmentsContextLoadState,
      breadcrumbsEnabled
    } = this.props;

    const assessmentAnswersLoadcontext = study
      ? `studies/${study.id}/assessment-answers?filter[assessment_type]=non_wound`
      : "";

    const assessmentAnswersLoaded =
      assessmentAnswersContextLoadState[assessmentAnswersLoadcontext] ==
      loadStates.loaded;

    const assessmentSchemasLoaded = assessmentAnswers
      .map(aa => {
        return assessmentsContextLoadState[aa.attributes.assessmentId];
      })
      .every(loadState => loadState == loadStates.loaded);

    if (!assessmentAnswersLoaded || !assessmentSchemasLoaded) {
      return <LoadingComponent />;
    }

    const answersList = assessmentAnswers.map(aa => {
      const {
        attributes: {
          createdAt,
          partnerMetadata: { assessRefDate }
        }
      } = assessmentAnswerGroups.find(
        aag => aag.id == aa.attributes.assessmentAnswerGroupId
      );

      return (
        <div key={aa.id}>
          <h3>
            {new Moment(assessRefDate ?? createdAt).utc().format("MMM D, YYYY")}
          </h3>
          <GridRow>
            <GridColumn sm="6">
              <SchemaAnswersComponent
                assessmentAnswer={aa}
                assessmentSchema={assessmentSchema}
              />
            </GridColumn>
            <GridColumn sm="6">
              {this.renderNonWoundAssessmentSummaryContainer(
                aa,
                assessmentSchema
              )}
            </GridColumn>
          </GridRow>
          <br />
          <hr />
          <br />
        </div>
      );
    });

    let content;
    if (!answersList.length) {
      content = <div className={styles["no-assessments"]}>No Assessments</div>;
    } else {
      content = (
        <div>
          <div className={styles["header-container"]}>
            <NonWoundAssessmentDetailsHeaderContainer
              assessmentAnswer={assessmentAnswers[0]}
              assessmentSchema={assessmentSchema}
            />
          </div>
          <hr />
          {answersList}
        </div>
      );
    }

    return (
      <div>
        {breadcrumbsEnabled
          ? this.renderBreadcrumbs()
          : this.renderControlNavbar()}
        {content}
      </div>
    );
  }
}
